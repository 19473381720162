/* ==========================================================================
   Layout / content
   ========================================================================== */


.page-content {
  @include wrapper();
  margin-top: 40px;
}

.page-content__wrapper {

  @include breakpoint(tablethor) {
    width: 55%;
  }

  @include breakpoint(laptop) {
    width: 65%;
  }
}

.page-content__content {
  background-color: $color-white;
  padding: 30px 45px;

  .title2 {
    max-width: 536px;

    &:not(:first-child) {
      margin-top: 50px;
    }

    &:before {
      content: "";
      width: 44px;
      height: 7px;
      background-color: $color-orange;
      display: block;
      position: absolute;
      left: -66px;
      top: 16px;
    }
  }
  .title3:not(:first-child) {
    margin-top: 70px;
  }

  ul {
    margin-left: 15px;

    li {
      margin-top: 15px;
      margin-bottom: 15px;
      text-align: left;
    }
  }
}


.page-content__post-content {
  //padding: 156px 45px 0 45px;

  &:before {
    content: "";
    background-color: $color-white;
    height: 120px;
    display: block;
  }

  .page-content__post-content-inner {
    position: relative;
    margin: -83px 45px 0 45px;

  }
}