/* ==========================================================================
   Components / WYSIWYG
   ========================================================================== */

  .wysiwyg {
    h1 {

    }

    h2 {

    }

    h3 {

    }

    h4 {

    }

    h5 {

    }

    p {

    }

    b {

    }

    a {
      text-decoration: none;

      &:hover {

      }
    }

    ul {
      li {
      }
    }

    blockquote {

    }
  }