/* ==========================================================================
   Layout / Footer
   ========================================================================== */

.footer {

}

/***** prefooter *****/
.prefooter {
  display: flex;
  flex-direction: column;
  p{
    color: white
  }
  @include breakpoint(tablethor) {
    flex-direction: row;
  }
}

.prefooter-content{
  background-color: $blue;
  flex: 2;
  padding: 50px 15px;
  .prefooter-content__undertitle{
    @include font-size(24px);
  }
  @include breakpoint(tablet) {
    padding: 50px;
  }
}
.prefooter-content__text{
  width: 100%;
  background-color: #077ac4;
  padding: 20px;
  @include breakpoint(tablethor) {
    width: 50%;
  }
  .prefooter-content__position{
    margin-left: 30px;
    position: relative;
    &:before{
      content: '';
      width: 20px;
      height: 20px;
      background: url("images/LOCATION.svg") center center/20px 20px no-repeat;
      position: absolute;
      left: -30px;
    }
  }
  button{
    width: 250px;
    border: 2px solid white;
    background: transparent;
    color: white;
    @include font-size(16px);
    font-family: $font-stack-common-bold;
    font-weight: bold;
    border-radius: 50px;
    height: 50px;
    position: relative;
    @include transition(all $durationSmall $easeInOut);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;

    &:before{
      content: '';
      width: 20px;
      height: 20px;
      background: url("images/PHONE.svg") center center/20px 20px no-repeat;
      @include transition(all $durationSmall $easeInOut);
      margin-right: 15px;
    }
    &:hover{
      background: white;
      color: $blue;
      &:before{
        background: url("images/PHONE_BLUE.svg") center center/20px 20px no-repeat;
      }
    }
  }
}
.prefooter-content__other{
  a{
    color: white;
    text-decoration-color: white;
    font-weight: bold;
    font-family: $font-stack-common-bold;
  }
}
.prefooter__img{
  flex: 1;
  position: relative;
  overflow: hidden;
  min-height: 300px;
  img{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: initial;
    width: auto;
    height: auto;
  }
}

/***** postfooter *****/
.postfooter {
  background: $pale-grey;
  text-align: center;
  padding: 30px;
  font-family: $font-stack-extra-light;
  a{
    color: black;
    text-decoration: none;
    font-weight: bold;
  }
}
