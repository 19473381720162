@charset 'UTF-8';

// Base
@import 'base/fonts';
@import 'base/variables';;
@import 'base/mixins';
@import "../../node_modules/rfs/scss";
@import 'base/base';
@import 'base/form';

// Components
@import 'components/button';
@import 'components/titles';
@import 'components/icons';
@import 'components/card';
@import 'components/wysiwyg';
@import 'components/breadcrumb';
//@import 'components/tarteaucitron';
@import 'components/blocks';

// Layout
@import 'layout/content';
@import 'layout/navigation';
@import 'layout/header';
@import 'layout/footer';


// Pages
@import 'pages/home';
