@mixin font-face($name, $variant, $weight: normal, $style: normal) {
  @font-face {
    font-family: '#{$name}-#{$variant}';
    src: url('fonts/#{$name}-#{$variant}.otf') format('opentype'),
    url('fonts/#{$name}-#{$variant}.otf') format('opentype');
    font-weight: $weight;
    font-style: $style;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
  }
}


@include font-face('Galatea', 'Extralight');
@include font-face('Galatea', 'Light');
@include font-face('Galatea', 'Regular');
@include font-face('Galatea', 'Medium');
@include font-face('Galatea', 'Semibold');
@include font-face('Galatea', 'Bold');
@include font-face('Galatea', 'ExtraBold');
