/* ==========================================================================
   Components / Formulaires
   ========================================================================== */
.form{
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.15));
}

.form__header{
  text-align: center;
  color: white;
  padding: 20px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  @include font-size(20px);
  &:before {
    content: '';
    position: absolute;
    width: 1100%; height: 1100%;
    top: 20px; right: -500%;
    background: $blue;
    transform-origin: 54% 0;
    transform: rotate(45deg);
    z-index: -1;
  }
  p{
    color: white;
    font-family:$font-stack-light;
    font-weight: 300;
    @include font-size(20px);
  }
}
.form__wrapper{
  @include clearfix;
  padding: 15px 20px 25px 30px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    width: 1100%; height: 1100%;
    bottom: 80px; left: -600%;
    background: $pale-grey;
    transform-origin: 54% 100%;
    transform: rotate(45deg);
    z-index: -1;
  }
  .form__title{
    @include font-size(17px);
    font-family:$font-stack-light;
    font-weight: 300;
    text-align: center;
  }
  .form__content{
    display: flex;
    flex-direction: column;
  }
  .form__flex{
    display: flex;
    flex-direction: column;
    @include breakpoint(tablet) {
      flex-direction: row;
    }
  }
  .form__group{
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    margin: 10px 10px 0 0;

    label{
      @include font-size(14px);
      font-family:$font-stack-medium;
      color: $blue;
      font-weight: 500;
      span{
        color: #b0b0b0;
        font-family: $font-stack-light;
        font-weight: 300;
        @include font-size(12px);
      }
    }
    input,
    textarea{
      min-height: 40px;
      width: 100%;
      padding: 5px 15px;
    }
  }
  .form__tel{
    flex: 4;
  }
  .form__hidden{
    opacity: 0;
    max-width: 0;
    padding: 0;
    margin: 0 !important;
    height: 0;
  }
  .form__cp{
    flex: 2;
  }
  .form__conditions{
    margin-top: 20px;
    font-family: $font-stack-common;
    @include font-size(11px);
    line-height: 15px;
    font-weight: 300;
    padding-left: 25px;
    position: relative;
    a{
      color: $blue;
      font-weight: 600;
      text-decoration: none;
    }
    input{
      position: absolute;
      left: 0;
    }
  }
  .form__submit{
    background-color: $blue;
    border-radius: 50px;
    height: 50px;
    width: 160px;
    color: white;
    font-family: $font-stack-common-bold;
    font-weight: bold;
    @include font-size(16px);
    align-self: center;
    margin-top: 35px;
    border: 2px solid $blue;
    @include transition(all $durationSmall $easeInOut);
    cursor: pointer;
    &:hover{
      background-color: transparent;
      color: $blue;
    }
  }
}