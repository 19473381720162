// Layout
$wrapperMax             : 1440px;
$wrapperMin             : 1440px;
$gutter-width           : 15px;


// Transitions
$durationSmall        : .25s;
$duration             : .6s;
$durationIntermediate : .75s;
$durationLong         : 1.2s;
$linear         : cubic-bezier(0.250, 0.250, 0.750, 0.750);
$ease           : cubic-bezier(0.250, 0.100, 0.250, 1.000);
$easeIn         : cubic-bezier(0.420, 0.000, 1.000, 1.000);
$easeOut        : cubic-bezier(0.000, 0.000, 0.580, 1.000);
$easeInOut      : cubic-bezier(0.420, 0.000, 0.580, 1.000);
$easeInSine     : cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine    : cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine  : cubic-bezier(0.445, 0.05, 0.55, 0.95);
$easeInOutQuart : cubic-bezier(0.77, 0, 0.175, 1);
$easeOutQuart   : cubic-bezier(0.165, 0.84, 0.44, 1);
$easeOutCubic   : cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic : cubic-bezier(0.645, 0.045, 0.355, 1);
$easeInOutCirc  : cubic-bezier(0.785, 0.135, 0.15, 0.86);
$easeInCirc     : cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeInOutQuint : cubic-bezier(0.86, 0, 0.07, 1);
$easeOutQuint   : cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutBack  : cubic-bezier(0.68, -0.55, 0.265, 1.55);
$easeOutBack    : cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInBack     : cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeInOutExpo  : cubic-bezier(1, 0, 0, 1);
$easeOutExpo    : cubic-bezier(0.19, 1, 0.22, 1);

// Palettes
$color-white      : #ffffff;
$color-black-full : #000000;
$color-black      : #1c1c1c;
$color-grey-dark  : #2d2d2d;
$color-grey-light : #f4f4f4;
$color-green      : #15D23B;
$color-orange     : #edad58;
$color-red        : #ff5252;

/*
// SOCIAL MEDIA COLORS
$facebook:       #3b5998;
$googleplus:     #dd4b39;
$instagram:      #517fa4;
$linkedin:       #007bb6;
$pinterest:      #cb2027;
$twitter:        #0dcfed;
$youtube:        #b00,
*/

// Custom

$pale-grey: #f4f5f8;
$blue: #0072bc;
$orange: #f09002;

// Font sizes
$base-font-size         : 16px;
$line-height            : 1.4;
$baseline               : $base-font-size * $line-height;
$base-color             : #2d2d2d;

// Font stacks
$font-stack-extra-light      : "galatea-extralight", sans-serif;
$font-stack-light      : "galatea-light", sans-serif;
$font-stack-common      : "galatea-regular", sans-serif;
$font-stack-medium      : "galatea-medium", sans-serif;
$font-stack-common-bold : "galatea-bold", sans-serif;
$font-stack-common-extra-bold : "Galatea-extrabold", sans-serif;