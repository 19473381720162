/* ==========================================================================
   Components / blocks
   ========================================================================== */


/***** block-quotation *****/
.block-quotation {
  margin: $gutter-width;
  position: relative;
  z-index: 10;
  //margin-top: -80px;


  @include breakpoint(tablethor) {
    position: absolute;
    //top: 4vh; //25px;
    width: calc(40% - #{$gutter-width});
    margin-top: 80px; //0;
    top: 0;
    max-width: 551px;
    right: $gutter-width;

    &.fixed {
      position: fixed;
      z-index: 10;
    }
  }
  @include breakpoint(laptop) {
    width: calc(28%);
    right: calc(5% - 15px);
  }

  @include breakpoint(calc(#{$wrapperMax} + 80px)) {
    right: calc((100vw - #{$wrapperMax}) / 3);
  }
}

.block-security{
  display: flex;
  align-items: flex-start;
  width: 90%;
  margin: 0 auto;
  h2{
    color: black;
  }
  @include breakpoint(tablet) {
    width: 100%;
    margin: 0;
    &__text{
      margin-left: 20px;
    }
  }
  p{
    margin: 0;
    &.bold {
      font-family: $font-stack-common-bold;
    }
  }
  img{
    width: 242px;
    height: 232px;
    display: none;
    @include breakpoint(tablet) {
      display: block;
    }
  }
  &__text{
    display: flex;
    flex-direction: column;
  }
  &__info{
    margin-bottom: 25px;
  }
  .plus{
    font-family: $font-stack-medium;
    @include font-size(22px);
    font-weight: 500;
    display: flex;
    align-items: center;
    &:before{
      content: '+';
      color: $blue;
      font-size: 32px;
      margin-right: 15px;
    }
  }
}

.block-tranquility{
  display: flex;
  margin-top: 100px;
  h2{
    color: black;
  }
  &__text{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__img{
    min-width: 300px;
    margin-left: 20px;
    display: none;
    @include breakpoint(laptop) {
      display: block;
    }
  }
  &__bottom{
    display: flex;
    flex: 1;
    align-items: flex-end;
    flex-direction: column;

    @include breakpoint(tablethor) {
      flex-direction: row;
    }
    p{
      margin: 0;
      align-self: center;
    }
  }
  &__palmares{
    height: 160px;
    min-width: 160px;
    padding: 10px;
    align-self: center;
    position: relative;
    margin: 15px;
    @include breakpoint(tablethor) {
      align-self: flex-end;
    }
    &:before{
      content: '';
      border-bottom: 88px solid $blue;
      border-right: 88px solid transparent;
      position: absolute;
      bottom: 0;
      z-index: -1;
      left: 0;
    }
    img{
      width: auto;
      height: 100%;
    }
  }
}
.block-exeptionnel{
  display: flex;
  margin: 100px auto 0 auto;
  width: 100%;
  background-color: $blue;
  flex-direction: column;
  padding: 20px;
  position: relative;
  &:before{
    content: '';
    border-top: 36px solid black;
    border-right: 36px solid transparent;
    position: absolute;
    right: 0;
    bottom: -36px;
  }
  @include breakpoint(tablethor) {
    width: 75%;
  }
  p{
    color: white;
    text-transform: uppercase;
    font-family: $font-stack-medium;
    @include font-size(20px);
  }
}

.block-pvc{
  margin-top: 100px;
  &__title{
    font-family: $font-stack-medium;
    @include font-size(22px);
    text-transform: uppercase;
    color: $orange;
  }
  &__container{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    article{
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: calc(45% - 20px);
      display: flex;
      box-sizing: border-box;
      margin: 10px;
      align-items: center;
      height: 100px;
      min-width: 300px;
      position: relative;
      overflow: hidden;
      padding: 0 20px;
      @include breakpoint(mobileonly) {
        margin: 0 0 10px 0;
      }
      &:before {
        content: '';
        position: absolute;
        width: 1100%; height: 1100%;
        bottom: 125px; left: -600%;
        background: $pale-grey;
        transform-origin: 54% 100%;
        transform: rotate(45deg);
        z-index: -1;
      }
    }
    img{
      height: 60px;
      width: auto;
    }
    h3{
      margin-left: 15px;
    }
  }
}

.block-reseau{
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  &__top{
    display: flex;
    flex-direction: column;
    align-items: center;
    @include breakpoint(tablethor) {
      flex-direction: row;
    }
    p{
      margin-left: 0;
      @include breakpoint(tablet) {
        margin-left: 50px;
      }
    }
  }
  &__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;
    margin-right: -10px;
  }
  article{
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: calc(33% - 10px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
    text-align: center;
    background-color: $pale-grey;
    padding: 25px;
    box-sizing: border-box;
    img{
      height: 60px;
      width: auto;
    }
    h3{
      margin-top: 15px;
    }
  }
}