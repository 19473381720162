/* ==========================================================================
   Components / MIXINS
   ========================================================================== */

/*********************
  BREAKPOINTS RESPONSIVE LAYOUT
  *********************/

@mixin breakpoint($point) {
  @if $point == largedesktop {
    @media (min-width: 1800px) { @content ; }
  }@else if $point == mediumdesktop {
    @media (min-width: 1500px) { @content ; }
  }@else if $point == desktop {
    @media (min-width: 1360px) { @content ; }
  }@else if $point == laptop {
    @media (min-width: 1200px) { @content ; }
  }@else if $point == tablethor {
    @media (min-width: 1000px) { @content ; }
  }@else if $point == tablet {
    @media (min-width: 760px) { @content ; }
  }@else if $point == phablet {
    @media (min-width: 576px)  { @content ; }
  }@else if $point == mobileonly {
    @media (max-width: 576px)  { @content ; }
  }@else if $point == smallMobile {
    @media (max-width: 325px)  { @content ; }
  }@else {
    @media (min-width: $point)  { @content ; }
  }
}

  // Wrapper
@mixin wrapper($type: 'max') {
  display: block;
  width: 90%;
  margin-left: auto;
  margin-right: auto;

  @if $type == 'max' {
    max-width: $wrapperMax;
  }@else {
    max-width: $wrapperMin;
  }
}

// Transition
@mixin transition($transition...) {
  transition: $transition;
}
@mixin transition-property($property...) {
  transition-property: $property;
}
@mixin transition-duration($duration...) {
  transition-property: $duration;
}
@mixin transition-timing-function($timing...) {
  transition-timing-function: $timing;
}

// Flex Row
@mixin flex-row {
  display: flex;
  flex-direction: row;
}

// Flex Column
@mixin flex-row {
  display: flex;
  flex-direction: column;
}




@mixin set-font-size($size, $height: null, $spacing: null) {
  @include rfs($size);  // responsive font size

  @if ($height != null) {
    $lineHeight: decimal-round($height / $size, 0.01);
    line-height: #{$lineHeight};
  }
  @if ($spacing != null) {
    $letter-spacing: decimal-round($spacing / $size, 0.01);
    letter-spacing: #{$letter-spacing}em;
  }
}

@mixin hover-focus {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
