/*-- RESET ALL --*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, input, textarea, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-weight:inherit;
  font-style:inherit;
  font-size:100%;
  font-family:inherit;
  vertical-align:baseline;
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

body {
  display: block;
  width: 100%;
  min-height: 100vh;
  background-color: white;
  font-family: $font-stack-common;
  font-size: $base-font-size;
  font-weight: 300;
  //line-height: $line-height;
  overflow-x: hidden;
  color: $base-color;
}
main{
  background-color: rgba(0,114,188,0.1);
  overflow: hidden;
  //&:before {
  //  content: '';
  //  position: absolute;
  //  width: 3000px; height: 3000px;
  //  background: white;
  //  transform-origin: 50% 0;
  //  transform: rotate(45deg)  translateY(-50%);
  //  z-index: 0;
  //  top: 50%;
  //  right: 0;
  //  @include breakpoint(tablet) {
  //    right: 20%;
  //  }
  //}
}
b, strong {
  font-weight: normal;
  font-family: $font-stack-common-bold;
}

em, i {
  font-style: italic;
}

p {
  font-family: $font-stack-extra-light;
  font-weight: 200;
  color: $base-color;
  @include set-font-size(18px);
  &:not(:first-child) {
    margin-top: 1em;
  }
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

a {
  &:hover {
    @include transition(all $durationSmall $easeInOut);
  }
}

img {
  width: 100%;
  height: auto;
  max-width: 300px;
}

//a11y = accessibility
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
    clip-path: none;
  }
}

.clearfix {
  @include clearfix;
}

.h1-title{
  font-family: $font-stack-common-extra-bold;
  @include set-font-size(42px);
  color: white;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-weight: 800;
  text-transform: uppercase;
  width: 100%;
}

.h2-title{
  font-family: $font-stack-common;
  @include set-font-size(30px);
  color: white;
  text-transform: uppercase;
}

.h3-title{
  font-family: $font-stack-common;
  @include set-font-size(18px);
  color: black;
  text-transform: uppercase;
  width: 100%;
}

.blue-text{
  @include set-font-size(18px);
  line-height: 22px;
  color: $blue;
  font-family: $font-stack-common;
}
.page-container{
  position: relative;
  z-index: 1;
  padding: 75px 0;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    width: 3000px; height: 3000px;
    background: white;
    transform-origin: 50% 0;
    transform: rotate(45deg)  translateY(-50%);
    z-index: -1;
    top: 50%;
    right: 0;
    @include breakpoint(tablet) {
      right: 20%;
    }
  }
}