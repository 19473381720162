/* ==========================================================================
   Layout / Header
   ========================================================================== */

.header {
  background: url('images/header.jpg') no-repeat center center;
  background-size: cover;
  padding-top: 10px;
  position: relative;
  &:before{
    content: '';
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    background: black;
    opacity: 0.2;
  }
  @include breakpoint(tablet) {
    padding-top: 20px;
  }
}

.header.confirmation{
  height: calc(100vh - 20px);
  .header__content{
    text-align: center;
    width: 100%;
  }
  .header__text{
    align-items: center;
  }
}

.header__wrapper {
  @include wrapper();
  color: $color-white;
  font-family: $font-stack-common;
  @include set-font-size(27px);
}
.header__content {
  text-align: center;
  position: relative;
  z-index: 1;
  @include breakpoint(tablethor) {
    width: 45%;
    text-align: left;
    padding-top: 60px;
  }
}
.header__text {
  padding-top: 20px;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include breakpoint(laptop) {
    padding: 70px 0;
    align-items: flex-start;
  }
  .retour{
    color: white;
    text-decoration: none;
    border: 2px solid white;
    height: 50px;
    background: transparent;
    padding: 5px 25px;
    border-radius: 50px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    &:hover{
      background: white;
      color: $blue;
    }
  }
}

.header__orange{
  background-color: $orange;
  padding: 25px;
  margin-top: 20px;
  max-width: 500px;
  position: relative;
  &:before{
    content: '';
    border-top: 36px solid black;
    border-right: 36px solid transparent;
    position: absolute;
    right: 0;
    bottom: -36px;
  }
  h2{
    text-transform: none;
  }
  p{
    font-family: $font-stack-light;
    @include set-font-size(22px);
    font-weight: 300;
    color: white;
  }
}
